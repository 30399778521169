import React from "react";

const ImageLoader = ({ src, alt }: { src: string; alt: string }) => {
    const [isLoaded, setIsLoaded] = React.useState(false);

    return (
        <div>
            {!isLoaded && <img className="d-block w-100" src="/images/placeholder.webp" alt={alt} />} {/* Show a loading message or spinner */}
            <img
                className="d-block w-100"
                src={src}
                alt={alt}
                onLoad={() => setIsLoaded(true)} // Mark as loaded when the image is ready
                style={{ display: isLoaded ? 'block' : 'none' }} // Hide image until loaded
            />
        </div>
    );
};

export default ImageLoader;