import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap-icons/font/bootstrap-icons.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Countdown from './Countdown/Countdown';

const API_URL = process.env.REACT_APP_API_URL;

function NavBarIndex() {
    const [activado, setActivado] = useState(false);
    const [fechaIni, setfechaIni] = useState("");
    const [fechaFin, setfechaFin] = useState("");

    const checkAspirante = async () => {
        try {
            const response = await axios.get(`${API_URL}/aspirante/check`)
            const items = response.data
            if (items.isActive === "true") {
                setActivado(true);
                setfechaFin(items.fechaFin);
                setfechaIni(items.fechaIni);
            }
        } catch (error) {
            //console.log('Error: there was an error')
        }
    }

    useEffect(() => {
        checkAspirante();
    }, [activado]);

    return (
        <>
            <Navbar expand="xl" className="bg-body-tertiary AppHeader" bg='dark' data-bs-theme='dark'>
                <Container fluid>
                    <Navbar.Brand as={Link} to={'/'}>
                        <img
                            alt=""
                            src="/images/UPlogoblancoN.png"
                            className="d-inline-block align-top topLogo"
                        />
                    </Navbar.Brand>
                    {activado && <Countdown linkBefore='https://2023.upchiapas.edu.mx/oferta_educativa/' linkAfter='/Admision' className='itemAspiranteNoClick' textoAfter="NUEVO INGRESO" textoBefore="CONVOCATORIA" fechaFin={fechaIni} />}
                    {/* <Col xs={12} md={7} className='colAspirante'>{activado && <Nav.Link href="#home" className='btn btn-lg itemAspirante ms-auto'>ASPIRANTES</Nav.Link>}</Col>
                    <Col className='my-1' xs={12} md={5}> */}
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto TextMenu">
                            <Nav.Link to="/Estudiantes" className='itemTopHeader' as={Link}>Estudiantes</Nav.Link>
                            <Nav.Link href="https://platinum.upchiapas.edu.mx" className='itemTopHeader'>Docentes & Staff</Nav.Link>
                            <Nav.Link to="/Familia" className='itemTopHeader' as={Link}>Familia</Nav.Link>
                            <Nav.Link to="/Egresados" className='itemTopHeader' as={Link}>Egresados</Nav.Link>
                            <Nav.Link href='https://platinum.upchiapas.edu.mx/alumnos' className='pt-1 platinumNav'>PLATINUM</Nav.Link>
                            {activado && <Nav.Item className='itemAspiranteNav'><Countdown linkBefore='https://2023.upchiapas.edu.mx/oferta_educativa/' linkAfter='/Admision' className='' textoAfter="NUEVO INGRESO" textoBefore="CONVOCATORIA" fechaFin={fechaIni} /></Nav.Item>}
                        </Nav>

                    </Navbar.Collapse>
                    {/* </Col> */}
                </Container>
                <Nav.Link href='https://platinum.upchiapas.edu.mx/alumnos' className='pt-1 platinum'>PLATINUM</Nav.Link>
            </Navbar>
        </>
    );
}

export default NavBarIndex;