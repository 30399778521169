import React, { useEffect, useState } from "react";
import { Nav, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

interface CountParams {
    fechaFin: number | string | Date;
    textoBefore?: string;
    textoAfter: string;
    className: string;
    linkBefore?: string;
    linkAfter?: string;
}

const Countdown: React.FC<CountParams> = ({ fechaFin, textoBefore, textoAfter, className, linkAfter, linkBefore = "" }) => {
    // Define the shape of timeLeft
    interface TimeLeft {
        días?: number;
        horas?: number;
        minutos?: number;
        segundos?: number;
    }

    const calculateTimeLeft = (): TimeLeft => {
        const targetDate = new Date(fechaFin);
        const now = new Date();
        const difference = targetDate.getTime() - now.getTime();

        if (difference > 0) {
            return {
                días: Math.floor(difference / (1000 * 60 * 60 * 24)),
                horas: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutos: Math.floor((difference / (1000 * 60)) % 60),
                segundos: Math.floor((difference / 1000) % 60),
            };
        } else {
            return {};
        }
    };

    const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft());
    //const [year] = useState<number>(new Date().getFullYear());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const timerComponents: JSX.Element[] = Object.keys(timeLeft).map((interval) => {
        const key = interval as keyof TimeLeft;
        const value = timeLeft[key] ?? 0;

        return (
            (key !== "segundos") ?
                <span key={key}>
                    {value < 10 ? `0${value} : ` : `${value} : `}
                </span>
                :
                <span key={key}>
                    {value < 10 ? `0${value} ` : `${value} `}
                </span>
        );
    });

    return (
        <>
            {timerComponents.length ?
                <Row className={className}>
                    {(linkBefore !== "") ?
                        <Nav.Link to={linkBefore} as={Link} className='my-auto'>
                            <div className="my-auto">
                                {textoBefore}<br />{timerComponents}
                            </div>
                        </Nav.Link>
                        :
                        <div className="my-auto">
                            {textoBefore}<br />{timerComponents}
                        </div>
                    }
                </Row>
                :
                <Row className={className}>
                    {(linkAfter) ?
                        <Nav.Link to={'/Admision'} as={Link} className='my-auto'>
                            {textoAfter}
                        </Nav.Link>
                        :
                        textoAfter
                    }
                </Row>
            }
        </>
    );
};

export default Countdown;