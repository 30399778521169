import axios from "axios";
import React from "react";
import HeaderWrapper from "./HeaderWrapper"
import NewsMosaic from "./Mosaic/Mosaic";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "./Footer";

interface NewsItem {
    fecha_pub: string,
    img_principal: string,
    cuerpo: string,
    titulo: string,
    url: string,
    id_enlace: number
}

const API_URL = process.env.REACT_APP_API_URL;

const AllNews: React.FC = () => {

    const [newsData, setNewsData] = React.useState<NewsItem[]>([])

    const getInfo = async () => {
        try {
            const response = await axios.get(`${API_URL}/enlaces/noticiasA`)
            const items = response.data;
            setNewsData(items);
        } catch (error) {
            console.log('Error: ', error)
        }
    }

    React.useEffect(() => {
        getInfo();
    }, []);

    return (
        <>
            <HeaderWrapper />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <div className="d-flex justify-content-center">
                            <h1 className="pt-5 pb-5">NOTICIAS</h1>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <NewsMosaic newsItems={newsData} mode={0} />
                </Row >
            </Container>
            <Footer />
        </>
    )

}

export default AllNews;