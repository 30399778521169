import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import ContentPage from './Components/content';
import IndexWrapper from './Components/indexWrapper';
import FileDisplay from './Components/file';
import ReactGA from 'react-ga4'
import NotFound from './Components/404NotFound';
import AllNews from './Components/News';

// Inicializa Google Analytics con tu ID de seguimiento
const initGA = (): void => {
  ReactGA.initialize('G-BL395175DZ');
  console.log('Google Analytics initialized');
};

// Registrar la vista de página en cada cambio de ruta
const logPageView = (pathname: string, search: string): void => {
  const page = pathname + search; // Combina pathname y search para obtener la URL completa
  const location = window.location.href; // Asegura que la ubicación actual (dl) se obtenga de window.location.href

  // Actualiza tanto el `page` (dp) como el `location` (dl)
  ReactGA.set({
    page: page,
    location: location, // Usa window.location.href para dl
  });

  ReactGA.send(page); // Envía la vista de página a GA
};

// Componente para manejar Google Analytics en cada cambio de ruta
const AnalyticsHandler: React.FC = () => {
  const location = useLocation(); // Usa useLocation para detectar cambios de ruta
  const { pathname, search } = location;

  useEffect(() => {
    logPageView(pathname, search); // Llama a logPageView cuando cambie la ubicación
  }, [pathname, search]); // Escucha los cambios en pathname y search

  return null;
};

const App: React.FC = () => {
  useEffect(() => {
    initGA(); // Inicializa Google Analytics una sola vez al cargar la aplicación
  }, []);

  return (
    <Router>
      <AnalyticsHandler />
      { /* <SidePanel titulo="Visita el sitio web anterior" contenido="<h4><a href='https://2023.upchiapas.edu.mx'>https://2023.upchiapas.edu.mx</a></h4>" /> */ }
      <Routes>
        <Route path='/' element={<IndexWrapperWithTitle />} />
        <Route
          path='/noticias'
          element={<NesWrapperWithTitle />}
        />
        <Route
          path='/Oferta_Educativa/*'
          element={<RedirectToExternal url="https://2023.upchiapas.edu.mx/oferta_educativa" title="Oferta Educativa" />}
        />
        <Route
          path='/registro_empresa/*'
          element={<RedirectToExternal url="https://2023.upchiapas.edu.mx/registro_empresa" title="Registro de Empresas Estancia/Estadía" />}
        />
        <Route
          path='/nosotros/*'
          element={<RedirectToExternal url="https://www.upchiapas.edu.mx/Universidad" title="Nosotros" />}
        />
        <Route
          path='/aspirantes/*'
          element={<RedirectToExternal url="https://www.upchiapas.edu.mx/Admision" title="Aspirantes" />}
        />
        <Route
          path='/alumnos/*'
          element={<RedirectToExternal url="https://www.upchiapas.edu.mx/Estudiantes" title="Estudiantes" />}
        />
        <Route path='/:url' element={<ContentPageWithTitle />} />
        <Route path='/file/:id_enlace/:filename' element={<FileDisplay />} />
        <Route path='/media/:id_enlace/files/:filename' element={<FileDisplay />} />
        <Route
          path='/coepes/*'
          element={<RedirectToExternal url="https://2023.upchiapas.edu.mx/coepes" title="COEPES" />}
        />
        <Route path='/404' element={<NotFound />} />
      </Routes>
    </Router>
  );
};

// Componente que redirige a una URL externa
const RedirectToExternal: React.FC<{ url: string, title: string }> = ({ url, title }) => {
  usePageTitle(title)

  React.useEffect(() => {
    window.location.href = url; // Redirección completa a la URL externa
  }, [url]);

  return null; // No renderiza nada en la UI
};

// Componente para manejar el título en el Index
const IndexWrapperWithTitle = () => {
  usePageTitle('Universidad Politécnica de Chiapas');
  return <IndexWrapper />;
};

// Componente para manejar el título en el Index
const NesWrapperWithTitle = () => {
  usePageTitle('UPChiapas - Noticias');
  return <AllNews />;
};

// Componente para manejar el título en ContentPage
const ContentPageWithTitle = () => {
  const { pathname } = useLocation();
  usePageTitle(`UPChiapas - ${pathname.replace('/', '')}`);
  return <ContentPage />;
};

// Hook personalizado para cambiar el título de la página
const usePageTitle = (title: any) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
};

export default App;