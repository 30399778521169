import React from "react";
import axios from "axios";
import { SliderItem } from "../Interfaces/Interfaces";
import Slider from "./Slider/Slider";
import { Carousel } from "react-bootstrap";
import ImageLoader from "./ImageLoader/ImageLoader";

//const Slider = React.lazy(() => import("./Slider/Slider"));

const API_URL = process.env.REACT_APP_API_URL;

const MainSlider: React.FC = () => {
    const [items, setItems] = React.useState<SliderItem[] | null>(null);

    const getItems = async () => {
        try {
            const response = await axios.get(`${API_URL}/enlaces/slider`);
            const result = response.data;
            setItems(result);
        } catch (error) {
            console.error("Error fetching slider items:", error);
        } finally {
        }
    };

    React.useEffect(() => {
        getItems();
    }, []);

    if (!items || items.length === 0) {
        return (
            <Carousel>
                <Carousel.Item>
                    <ImageLoader src="/images/placeholder.webp" alt="Loading ..." />
                </Carousel.Item>
            </Carousel>
        );
    }

    return (
        <Slider items={items} />
    );
};

export default MainSlider;
